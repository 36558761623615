import React, { ReactElement } from "react"
import { LocationsList as LocationsListBase } from "@geome/react-components-next/lib/components/locationsList"
import { PennzoilLocation } from "../../types"
import { LocationsListItem } from "@geome/react-components-next/lib/components/locationsList/item"
import { Address } from "../address"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { DirectionsButton } from "../directionsButton"
import { TelephoneLink } from "../telephoneLink"
import { SiteType } from "../siteType"
import { BottleIcon } from "../icons/bottle"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"

export const LocationsList = (): ReactElement => {
  const { translate } = useI18n()

  return (
    <BlockScopingContextProvider block="locations-list">
      <LocationsListBase endpoint="nearest_to" resource="locations">
        {(location: PennzoilLocation) => (
          <LocationsListItem
            key={location.id}
            isSplitButton
            resource="locations"
            location={location}
            renderContent={(_, props) => (
              <div className="locations-list__item-info" {...props} tabIndex={0}>
                <div className="locations-list__item-info-name">
                  <span className="locations-list__item-info-name-index">
                    {location.index}
                    {"."}
                  </span>
                  {location.name}
                </div>
                <Address location={location} />
                <SiteType location={location} />

                {location.lube_products.includes("pennzoil_ultra") && (
                  <div className="locations-list__item-promotion">
                    <BottleIcon />
                    {translate(`info.has_pennzoil_ultra.${location.user_type}`)}
                  </div>
                )}

                {location.telephone && <div>{translate("info.to_make_appointment")}</div>}
              </div>
            )}
            renderAfterContent={(_, props) => (
              <>
                <div className="locations-list__item-directions">
                  <DirectionsButton location={location} />
                </div>
                <div className="locations-list__item-below-directions" {...props}></div>
                {location.telephone ? (
                  <TelephoneLink
                    className="locations-list__item-call"
                    location={location}
                    onMouseEnter={props.onMouseEnter}
                    onMouseLeave={props.onMouseLeave}
                  />
                ) : undefined}
              </>
            )}
          />
        )}
      </LocationsListBase>
    </BlockScopingContextProvider>
  )
}
