import { useSubscribeAndWaitForChange } from "./hooks"
import { PennzoilLocation } from "../../types"
import { useSelectedGooglePlace } from "@geome/recoil/dist/feature/places/hooks"
import { useAPIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { Interaction } from "@geome/react-next/lib/context/userInteraction/types"
import { onCompletionSelect, onFilterToggle, sendLocationSelect } from "./callbacks"
import { sendAnalytics } from "./sendAnalytics"
import { useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"

export const Analytics = (): null => {
  const selectedPlace = useSelectedGooglePlace()
  const selectedLocation = useSelectedLocation<PennzoilLocation>()
  const filters = useAPIFilters()

  useSubscribeToUserInteraction("filters:filterGroups:toggle", onFilterToggle)

  useSubscribeToUserInteraction(
    "*:telephone-link:click",
    (topic, { href, location }: { href: string; location: PennzoilLocation }) => {
      const [block, component, action] = topic.split(":")
      sendAnalytics({
        action: "phoneclick",
        text: JSON.stringify({ id: location.id, name: location.name }),
        eventCategory: `${block}.${component}`,
        eventAction: action as Interaction,
        eventLabel: `${location.id} - ${href}`,
      })
    }
  )

  useSubscribeToUserInteraction(
    "*:directions-link:click",
    (topic, { href, location }: { href: string; location: PennzoilLocation }) => {
      const [block, component, action] = topic.split(":")
      sendAnalytics({
        action: block === "locations-list" ? "directionsonresults" : "directionsoncard",
        eventCategory: `${block}.${component}`,
        eventAction: action as Interaction,
        eventLabel: `${location.id} - ${href}`,
      })
    }
  )

  useSubscribeToUserInteraction(
    "*:location:select",
    (_topic, { location }: { location: PennzoilLocation }): void => {
      sendLocationSelect(location, filters)
    }
  )

  useSubscribeAndWaitForChange("*:completion:select", onCompletionSelect, {
    selectedLocation,
    selectedPlace,
    filters,
  })

  return null
}
