export default {
  info: {
    has_pennzoil_ultra: {
      diy: "Vend Pennzoil Ultra Platinum™",
      difm: "Utilise Pennzoil Ultra Platinum™",
    },
  },
  search: {
    placeholder: "Recherche d'un emplacement",
    your_location: "Utiliser mon emplacement",
    use_my_location: "Utiliser mon emplacement",
    geolocation_status: {
      none: "Utiliser mon emplacement",
    },
  },
  info_window: {
    directions_link_no_distance: "<u>Chercher</u>",
    directions_link: "<u>Chercher</u> ({distance, number, distance} kilomètres)",
  },
  directions: { distance: "{distance, number, distance} KM" },
  filters: {
    title: "Filtres",
    button: {
      aria_text: "Filtres",
    },
  },
  user_types: {
    difm: "Établissements de vidange d'huile",
    diy: "Établissements de détail",
  },
}
