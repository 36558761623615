import React, { useCallback } from "react"
import { Marker } from "@geome/react-components-next/lib/components/map/google/marker"
import { usePublishUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { useHighlightLocation } from "@geome/recoil/dist/feature/locations/hooks"
import { useSelectLocation } from "@geome/recoil/dist/feature/selection/hooks"
import { PennzoilLocation } from "../../types"
import { usePennzoilIcon } from "./hooks/usePennzoilIcon"

interface PennzoilMarkerProps {
  location: PennzoilLocation
}

export const PennzoilMarker = ({ location }: PennzoilMarkerProps): JSX.Element => {
  const [onMarkerClick] = usePublishUserInteraction("location", "select")

  const icon = usePennzoilIcon(location)
  const onClick = useSelectLocation(location, "locations")

  const handleClick = useCallback(() => {
    onClick()
    onMarkerClick({ location })
  }, [location, onMarkerClick, onClick])

  const { highlight, unhighlight } = useHighlightLocation(location, "locations")
  return (
    <Marker
      lat={location.lat}
      lng={location.lng}
      icon={icon}
      onClick={handleClick}
      onMouseover={highlight}
      onMouseout={unhighlight}
    />
  )
}
