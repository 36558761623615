import { useLocations } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement } from "react"
import { PennzoilMarker } from "./pennzoilMarker"
import { PennzoilLocation } from "../../types"

export const Locations = (): ReactElement => {
  const locations = useLocations<PennzoilLocation>({
    resource: "locations",
    endpoint: "within_bounds",
  })
  return (
    <>
      {locations.map((location) => (
        <PennzoilMarker key={location.id} location={location} />
      ))}
    </>
  )
}
