import { useGoogleDirectionsLink } from "../../hooks/directions"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import React, { ReactElement } from "react"
import { PennzoilLocation } from "../../types"
import { DirectionsIcon } from "../icons/directions"
import { Link } from "@geome/react-components-next/lib/components/link"

export const DirectionsButton = ({ location }: { location: PennzoilLocation }): ReactElement => {
  const { translate } = useI18n()

  const directionsLink = useGoogleDirectionsLink({ lat: location.lat, lng: location.lng })

  return (
    <Link
      name="directions-link"
      className="directions-button"
      href={directionsLink}
      target="_blank"
      interactionData={{ href: directionsLink, location }}
      tabIndex={0}
    >
      <DirectionsIcon />
      {location.distance !== undefined && (
        <>
          {translate("directions.distance", {
            convert: true,
            distance: location.distance,
          })}
        </>
      )}
    </Link>
  )
}
