import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { useResetRecoilState } from "@geome/recoil"
import { useSelectedLocation } from "@geome/recoil/dist/feature/locations/hooks"
import React, { ReactElement, useMemo } from "react"
import { PennzoilLocation } from "../../types"
import { CloseIcon } from "../icons/close"
import { SiteType } from "../siteType"
import { selectedLocationIdAtom } from "@geome/recoil/dist/feature/locations/atoms/index"
import { Address } from "../address"
import { TelephoneLink } from "../telephoneLink"
import { BottleIcon } from "../icons/bottle"
import { useGoogleDirectionsLink } from "../../hooks/directions"
import { DirectionsIcon } from "../icons/directions"
import { Link } from "@geome/react-components-next/lib/components/link"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { useReselectFromHistory } from "@geome/recoil/dist/feature/selection/hooks"

export const InfoWindow = (): ReactElement | null => {
  const { translate } = useI18n()
  const selectedLocation = useSelectedLocation<PennzoilLocation>()
  const closeInfoWindow = useResetRecoilState(selectedLocationIdAtom)
  const returnToSearch = useReselectFromHistory({ type: "google_place" })
  const closeAndReturnToSearch = (): void => {
    closeInfoWindow()
    returnToSearch()
  }

  const directionsLink = useGoogleDirectionsLink({
    lat: selectedLocation?.lat ?? 0,
    lng: selectedLocation?.lng ?? 0,
  })

  const isVeryClose = useMemo(
    () => !selectedLocation?.distance || selectedLocation.distance < 0.2,
    [selectedLocation?.distance]
  )

  if (!selectedLocation) return null

  return (
    <BlockScopingContextProvider block="info-window">
      <div className="info-window">
        <div className="info-window__title">
          {selectedLocation.name}
          <ButtonWithIcon
            className="info-window__close-button"
            text={translate("info_window.close") as string}
            textPosition="tooltip"
            icon={<CloseIcon />}
            onClick={closeAndReturnToSearch}
            name="close-button"
          />
        </div>

        <Address location={selectedLocation} />
        <SiteType location={selectedLocation} />
        <div className="info-window__icon-then-text">
          <DirectionsIcon scale={0.5} />
          <Link
            name="directions-link"
            className="info-window__directions-link"
            href={directionsLink}
            target="_blank"
            interactionData={{ href: directionsLink, location: selectedLocation }}
          >
            {translate(`info_window.directions_link${isVeryClose ? "_no_distance" : ""}`, {
              convert: true,
              distance: selectedLocation.distance,
              u: (s) => <u>{s}</u>,
            })}
          </Link>
        </div>
        {selectedLocation.lube_products.includes("pennzoil_ultra") && (
          <div className="info-window__icon-then-text">
            <BottleIcon />
            {translate(`info.has_pennzoil_ultra.${selectedLocation.user_type}`)}
          </div>
        )}
        {selectedLocation.telephone && (
          <>
            {translate("info.to_make_appointment")}
            <TelephoneLink className="info-window__telephone-link" location={selectedLocation} />
          </>
        )}
      </div>
    </BlockScopingContextProvider>
  )
}
