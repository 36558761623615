import { AppConfig } from "@geome/types"

export const FiltersConfig: AppConfig["filters"] = {
  enabledGroups: ["user_types", "lube_products"],
  groups: {
    user_types: {
      mode: "withAny",
      enabled: ["difm", "diy"],
    },
    lube_products: {
      mode: "withAny",
      enabled: ["pennzoil_ultra"],
    },
  },
}
