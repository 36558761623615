import React, { ReactElement } from "react"

export const DirectionsIcon = ({ scale = 1 }: { scale?: number }): ReactElement => (
  <svg
    fill="none"
    width={42 * scale}
    height={41 * scale}
    viewBox="0 0 42 41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="40"
      rx="5"
      stroke="#222"
      width="40"
      x="1.14209"
      y=".5"
      className="directions-icon__background"
    />
    <path
      className="directions-icon__arrow"
      clipRule="evenodd"
      d="m22.1399 26.3205 9-8.6602-9-8.6603v6.6603h-7-1-3v16h4v-12h7z"
      fillRule="evenodd"
    />
  </svg>
)
